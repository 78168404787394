import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import styles from './CotizarFormStep2.module.scss';
import ModalHours from '../Plans/ModalBenefits/ModalHours';
import { StateContext } from '../StateContextParent/StateContextParent';
import BenefitRow from './BenefitRow/BenefitRow';
import { IntlContext } from '../../intl/index';
import * as translations from './intl';
import { LocationContext } from '../Location/Location';
import { formatBenefits } from './formatBenefits';
import Plans from '../Plans/Plans';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FormBarStep1 from '../FormBarStep1/FormBarStep1';
import ModalBenefits from '../Plans/ModalBenefits/ModalBenefits';
import { dataLayerPageViewEventTrack } from '../../utils/GTM_helper';

const getProducts = (data, country, Quote) => {
  if (data.QuoteKAYAK) {
    let result = [];
    if (country === 'mx' || country === 'km') {
      const TripInitialDate = moment(Quote.TripInitialDate);
      const TripEndDate = moment(Quote.TripEndDate);
      const isAfterOrSame17Days = TripEndDate.diff(TripInitialDate, 'days') >= 17;
      result = data.QuoteKAYAK.ProductKAYAK.map(item => {
        const {
          Product, ProductCode, BenefitKAYAK, Document,
        } = item;
        const NewProduct = Product;
        if (
          isAfterOrSame17Days
          && Product
          && (Product.Name === 'Executive' || Product.Name === 'Elite')
        ) {
          NewProduct.isStudent = true;
        }
        return {
          Product: NewProduct,
          ProductCode,
          BenefitKAYAK,
          Document,
        };
      });
    } else {
      result = data.QuoteKAYAK.ProductKAYAK;
    }
    return result;
  }
  return {};
};

const renderRowItem = dataItem => (dataItem.content.detail != '- -' ? (
  <li key={dataItem.Id}>
    <BenefitRow
      list={[
        {
          description: dataItem.description,
          tooltip: dataItem.tooltip,
        },
        ...dataItem.content,
      ]}
    />
    <div className="dropdown-divider" />
  </li>
) : '');
const createSliderPlans = (dataList, showModal, setShowModal) => (dataList.listPlanes
  ? dataList.listPlanes.map((list, index) => (
    <div key={index} className={list.Recommended ? '' : styles.marginTop}>
      <Plans
        plan={list}
        descriptionBenefits={dataList.descriptionBenefits}
        index={index}
        optionalBenefits={dataList.optionalBenefits}
        complementaryBenefits={dataList.complementaryBenefits}
        dataList={dataList}
        showModal={showModal}
        setShowModal={setShowModal}
        key={`Plans-${index}`}
      />
    </div>
  ))
  : undefined);

const getNumberSlideToShow = dataList => {
  if (dataList.listPlanes) {
    return dataList.listPlanes.length > 3 ? 4 : dataList.listPlanes.length;
  }
  return 0;
};

function Arrow({ direction, onClick }) {
  const classArrow = direction === 'left' ? styles.btnLeft : styles.btnRight;
  const icon = direction === 'left' ? faChevronLeft : faChevronRight;
  return (
    <span className={`${styles.btnArrow} ${classArrow}`} onClick={onClick}>
      <FontAwesomeIcon icon={icon} color="#009ae5" />
    </span>
  );
}

const getIndexRecommended = dataList => (dataList.listPlanes
  ? dataList.listPlanes.findIndex((list, index) => list.Recommended)
  : undefined);

function CotizarFormStep2(props) {
  const [dataList, setDataList] = useState({});
  const [showModal, setShowModal] = useState({
    show: false,
    index: 0,
  });
  const [showModalHours, setShowModalHours] = useState({
    show: false,
    index: 0,
  });
  const { utils } = useContext(StateContext);
  const { country, idiom } = useContext(LocationContext);
  const { Quote } = utils.getFormDataStep1();
  const { data } = utils.getFormDataStep2();
  const products = getProducts(data, country, Quote);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const mediaInt = Math.floor(parseFloat(products.length / 2));
  let slideReference = '';

  const { history, setLoading, location } = props;

  const [cotizarMobile, setCotizarMobile] = useState();
  const windowWidth = window.matchMedia('(max-width: 800px)');

  const verifyWindowWidth = e => {
    if (e.matches) {
      setCotizarMobile(true);
      return;
    }
    setCotizarMobile(false);
  };

  useEffect(() => {
    verifyWindowWidth(windowWidth);
    windowWidth.addListener(verifyWindowWidth);
    return () => {
      windowWidth.removeListener(verifyWindowWidth);
    };
  }, []);

  const headerBenefits = products.map((product, index) => {
    const { Product, Document } = product;
    const defaultProduct = mediaInt === index;
    return {
      name: Product.Name,
      amount: Product.Amount,
      currency: Product.Currency,
      isStudent: Product.isStudent,
      document: Document,
      defaultProduct: Product.Recommended,
      amountWithoutPromotion: Product.AmountWithoutPromotion,
    };
  });

  const headerData = [
    { name: intl.LABEL_BENEFITS_INCLUDED },
    ...headerBenefits,
  ];

  const footerBenefits = products.map(product => {
    const { Product } = product;

    return {
      Id: Product.Id,
      name: Product.Name,
      PEkey: Product.PEkey,
      amount: Product.Amount,
      currency: Product.Currency,
      rate: Product.Rate,
      pdf_url: product.Document ? product.Document[0].url : '',
      Channel: Product.Channel,
    };
  });

  const footerData = [{ name: intl.LABEL_YOUR_QUOTE }, ...footerBenefits];

  useEffect(() => {
    dataLayerPageViewEventTrack(location.pathname, intl.PAGE_NAME_GTM_STEP);
  }, []);

  useEffect(() => {
    setDataList(formatBenefits(products, country, Quote));
    const hoursno = moment(Quote.TripInitialDate).diff(
      moment(new Date()),
      'hours',
    );
    if (hoursno <= 48){
      setShowModalHours({
        show: true,
        index: 0,
      });
    }
  }, [data]);

  useEffect(() => {
    if (cotizarMobile && slideReference) {
      const indexRecommended = getIndexRecommended(dataList);
      if (indexRecommended > -1) {
        slideReference.slickGoTo(indexRecommended);
      }
    }
  }, [dataList]);
  const content = dataList.descriptionBenefits
    && dataList.descriptionBenefits.map(dataItem => renderRowItem(dataItem));

  const contentComplementary = dataList.complementaryBenefits
    && dataList.complementaryBenefits.map(dataItem => renderRowItem(dataItem));

  const contentOptional = dataList.optionalBenefits
    && dataList.optionalBenefits.map(dataItem => renderRowItem(dataItem));

  const benefitSection = (title, list) => (
    <>
      <li className={styles.rowTitle} key={title}>
        <h4 className={styles.sectionTitle}>
          <strong>{title}</strong>
        </h4>
        <div className="dropdown-divider" />
      </li>
      {list}
    </>
  );

  const settings = {
    arrows: true,
    autoplay: false,
    infinite: false,
    speed: 300,
    variableWidth: false,
    slidesToShow: getNumberSlideToShow(dataList),
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          dots: false,
          speed: 300,
          centerMode: false,
          slidesToShow: getNumberSlideToShow(dataList) >= 2 ? 2 : 1,
          slidesToScroll: 1,
          arrows: true,
          nextArrow: <Arrow direction="right" />,
          prevArrow: <Arrow direction="left" />,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: false,
          speed: 300,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          nextArrow: <Arrow direction="right" />,
          prevArrow: <Arrow direction="left" />,
        },
      },
    ],
  };

  const renderPlans = createSliderPlans(dataList, showModal, setShowModal);
  return (
    <>
      {showModalHours.show && (
        <ModalHours
          showModalHours={showModalHours}
          setShowModalHours={setShowModalHours}
         />
      )}
      {showModal.show && (
        <ModalBenefits
          benefits={dataList.descriptionBenefits}
          optionalBenefits={dataList.optionalBenefits}
          complementaryBenefits={dataList.complementaryBenefits}
          indexProduct={showModal.index}
          product={dataList.listPlanes}
          showModal={showModal}
          setShowModal={setShowModal}
          dataList={dataList}
        />
      )}
      <FormBarStep1 setLoading={setLoading} history={history} />
      <div className={styles.cotizarHeader}>
        <h1 data-testid="formTitle" id="titleStep2">
          {intl.CHOOSE_THE_BEST_PLAN_FOR_YOU}
        </h1>
      </div>
      {dataList && dataList.listPlanes && dataList.listPlanes.length > 0 && (
        <div
          className={`${styles.boxPlans} ${styles.plansMobile} ${
            dataList.listPlanes.length === 2 ? styles.boxPlans_2plans : ''
          }`}
        >
          {/* eslint-disable-next-line no-return-assign */}
          <Slider {...settings} ref={slider => (slideReference = slider)}>
            {renderPlans}
          </Slider>
        </div>
      )}
    </>
  );
}

export default withRouter(CotizarFormStep2);
